import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Deadlifts 4-4-4-4\\@85% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`10-Deadlifts (225/155)`}</p>
    <p>{`4-T2B`}</p>
    <p>{`8-Deadlifts`}</p>
    <p>{`8-T2B`}</p>
    <p>{`6-Deadlifts`}</p>
    <p>{`12-T2B`}</p>
    <p>{`4-Deadlifts`}</p>
    <p>{`16-T2B`}</p>
    <p>{`2-Deadlifts`}</p>
    <p>{`20-T2B`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We are collecting blankets, scarves and gloves at Louisville East
and The Ville for the homeless this winter. If you’d like to donate
please do so by November 12th. There are bins at each location for your
donations. Thanks!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      